<template>
  <div class="app-container">
    <el-tabs v-model="activeName">
      <div class="copyoboard">
        <span class="copy-target">{{ url }}</span>
        <button
          class="copy-button"
          v-clipboard:copy="url"
          v-clipboard:success="clipboardSuccess"
          type="primary"
          icon="el-icon-document"
        >
          复制
        </button>
      </div>
    </el-tabs>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue'
import { handleClipboard, clipboardSuccess } from '@/utils/clipboard' // use clipboard directly

export default defineComponent({
  props: {
    url: {
      type: String,
      default: ''
    }
  },
  setup() {
    const dataMap = reactive({
      activeName: 'directly',
      clipboardSuccess: clipboardSuccess,
      handleClipboard: handleClipboard
    })
    return { ...toRefs(dataMap) }
  }
})
</script>
<style lang="scss" scoped>
.copyoboard{
  display: flex;
}
.copy-target{
  padding: 10px 20px;
  border: 1px solid #c3c3c3;
  color: #9d9799;
  box-sizing: content-box;
  border-right: none;
  border-radius: 15px 0 0 15px;
  max-width: 300px;
  word-break: break-word;
  background: #f4f4f4;
  text-align: left;
  min-width: 200px;
  display: flex;
  align-items: center;
}
.copy-button{
  max-width: 100px;
  color: #fff;
  background: #267901;
  border-radius: 0 15px 15px 0;
  border: none;
  padding: 20px 10px;
  min-width: 80px;
  outline: none;
  cursor: pointer;
}
@media screen and(max-width: 768px) {
  .copy-target{
    max-width: 80%;
    min-width: 150px;
  }
  .copy-button{
    max-width: 20%;
  }
}
</style>
