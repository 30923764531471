
import { defineComponent, reactive, toRefs } from 'vue'
import { handleClipboard, clipboardSuccess } from '@/utils/clipboard' // use clipboard directly

export default defineComponent({
  props: {
    url: {
      type: String,
      default: ''
    }
  },
  setup() {
    const dataMap = reactive({
      activeName: 'directly',
      clipboardSuccess: clipboardSuccess,
      handleClipboard: handleClipboard
    })
    return { ...toRefs(dataMap) }
  }
})
