<template>
  <div
    class="profile-container"
  >
    <template v-if="!loading">
      <ProfileHead
        :agentinfo="agentinfo"
        :hasProgress="false"
      />

      <div class="message-panel">
        <div class="item">
          <p>代理商名称：{{ agentinfo.agent_name }}</p>
          <p>角色：{{ enterprise }}</p>
          <p>
            手机号：
            <span v-show="!iseditmobile">{{ agentinfo.mobile }}</span>
            <input
              type="text"
              v-model="newmobile"
              @keyup.enter="alertNew('mobile')"
              v-show="iseditmobile"
            >
            <i
              :class="iseditmobile ? 'el-icon-check': 'el-icon-edit-outline'"
              @click="alertNew('mobile')"
              v-if="!agentinfo.mobile"
            />
          </p>
          <p>联系人：{{ agentinfo.contact_name }}</p>
          <p v-if="agentinfo.company_address">
            公司地址：
            <span v-show="!iseditaddress">{{ agentinfo.company_address }}</span>
            <input
              type="text"
              v-model="newaddress"
              @keyup.enter="alertNew('company_address')"
              v-show="iseditaddress"
            >
            <i
              :class="iseditaddress ? 'el-icon-check': 'el-icon-edit-outline'"
              @click="alertNew('company_address')"
            />
          </p>
          <!-- <p v-if="!isDistributor">
            权益兑换码：{{ agentinfo.activation_code }}
          </p> -->
        </div>
        <!-- <div
          class="item"
        >
          <p>
            当前代理级别：M{{ agentinfo.agent_level }}
            <span v-if="!isDistributor">
              （{{ agentinfo.fixed_level === 0 ? '浮动': '固定' }}）
            </span>
          </p>
          <p>分成比例：月销售额{{ agentinfo.divide }}</p>
          <p>结算周期：{{ agentinfo.settlement_cycle }}</p>
        </div> -->
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { reactive, defineComponent, toRefs, onBeforeMount, computed } from 'vue'
import ProfileHead from '@/components/profile-head/Index.vue'
import { baseMessage, baseEdit } from '@/apis/user'
import requestMiddle, { RequestInter } from '@/utils/request'
import { AGENTTYPE } from '@/store/modules/user/state'
import { useStore } from '@/store'
import { AgentType, IndexMessage } from '@/model/orderModel'
import { ElMessage } from 'element-plus'
import { isVaildTel } from '@/utils/validate'
 interface Profile {
  name: string
  email: string
  avatar: string
  roles: string
}
export default defineComponent({
  components: {
    ProfileHead
  },
  setup() {
    const store = useStore()
    const dataMap = reactive({
      agentinfo: {},
      loading: true,
      newmobile: '',
      newaddress: '',
      iseditmobile: false,
      iseditaddress: false,
      editLoad: false,
      getBaseMessage: async() => {
        const requestData = await requestMiddle({
          requestMethodName: baseMessage
        } as RequestInter)
        try {
          dataMap.agentinfo = requestData
        } catch (error) {
          console.log(error)
        }
        dataMap.loading = false
      },
      alertNew: async(type: string) => {
        const T = type === 'company_address' ? 'address' : type// 转换地址字段名
        if (type === 'mobile' && (dataMap.agentinfo as IndexMessage<string>)[type]) return false
        if (dataMap[`isedit${T}`] && dataMap[`new${T}`] !== (dataMap.agentinfo as IndexMessage<string>)[type] && !dataMap.editLoad) { // 确认是修改状态并且用户已修改
          if (type === 'mobile' && !isVaildTel(dataMap[`new${T}`])) { // 设置手机验证
            ElMessage.error('请输入正确手机号')
            return
          }
          dataMap.editLoad = true
          await baseEdit({ [T]: dataMap[`new${T}`] }).then((res) => {
            dataMap.editLoad = false
            if (res?.code === 200) {
              ElMessage({
                type: 'success',
                message: '修改成功'
              });
              (dataMap.agentinfo as IndexMessage<string>)[type] = dataMap[`new${T}`]
            }
          })
        } else if (!dataMap[`isedit${T}`]) {
          dataMap[`new${T}`] = (dataMap.agentinfo as IndexMessage<string>)[type]
        }
        dataMap[`isedit${T}`] = !dataMap[`isedit${T}`]
      }
    })
    const isDistributor = computed(() => {
      /* 1: 分成 2：分销 */
      return store.state.user.userinfo.agent_type === AgentType.DISTRIBUTOR
    })
    const enterprise = computed(() => {
      return store.state.user.userinfo.is_enterprise === AGENTTYPE.COMPANY ? '企业' : '个人'
    })
    onBeforeMount(() => {
      dataMap.getBaseMessage()
    })
    return { ...toRefs(dataMap), isDistributor, enterprise }
  }
})
</script>
<style lang="scss" scoped>
.profile-container{
   min-height: calc(100vh - 150px)
}
.message-panel{
  margin-top:30px;
  padding:50px 60px;
  background: #fff;
  p{
    margin-bottom: 30px;
    font-size: 18px;
    color: #333;
    i{
      margin-left:10px;
    }
    input{
      width: 400px;
      padding: 5px 10px;
      outline: none;
    }
  }
  .item:not(:first-child){
    padding-top:30px;
    border-top:1px solid #EEF2F5
  }
}
@media (max-width: 767px){
  .message-panel{
    padding:30px;
  }
}
@media (max-width: 575px){
  .message-panel p input{
    margin-top: 10px;
    max-width: 85%;
  }
}
</style>
