
import { reactive, defineComponent, toRefs, onBeforeMount, computed } from 'vue'
import ProfileHead from '@/components/profile-head/Index.vue'
import { baseMessage, baseEdit } from '@/apis/user'
import requestMiddle, { RequestInter } from '@/utils/request'
import { AGENTTYPE } from '@/store/modules/user/state'
import { useStore } from '@/store'
import { AgentType, IndexMessage } from '@/model/orderModel'
import { ElMessage } from 'element-plus'
import { isVaildTel } from '@/utils/validate'
 interface Profile {
  name: string
  email: string
  avatar: string
  roles: string
}
export default defineComponent({
  components: {
    ProfileHead
  },
  setup() {
    const store = useStore()
    const dataMap = reactive({
      agentinfo: {},
      loading: true,
      newmobile: '',
      newaddress: '',
      iseditmobile: false,
      iseditaddress: false,
      editLoad: false,
      getBaseMessage: async() => {
        const requestData = await requestMiddle({
          requestMethodName: baseMessage
        } as RequestInter)
        try {
          dataMap.agentinfo = requestData
        } catch (error) {
          console.log(error)
        }
        dataMap.loading = false
      },
      alertNew: async(type: string) => {
        const T = type === 'company_address' ? 'address' : type// 转换地址字段名
        if (type === 'mobile' && (dataMap.agentinfo as IndexMessage<string>)[type]) return false
        if (dataMap[`isedit${T}`] && dataMap[`new${T}`] !== (dataMap.agentinfo as IndexMessage<string>)[type] && !dataMap.editLoad) { // 确认是修改状态并且用户已修改
          if (type === 'mobile' && !isVaildTel(dataMap[`new${T}`])) { // 设置手机验证
            ElMessage.error('请输入正确手机号')
            return
          }
          dataMap.editLoad = true
          await baseEdit({ [T]: dataMap[`new${T}`] }).then((res) => {
            dataMap.editLoad = false
            if (res?.code === 200) {
              ElMessage({
                type: 'success',
                message: '修改成功'
              });
              (dataMap.agentinfo as IndexMessage<string>)[type] = dataMap[`new${T}`]
            }
          })
        } else if (!dataMap[`isedit${T}`]) {
          dataMap[`new${T}`] = (dataMap.agentinfo as IndexMessage<string>)[type]
        }
        dataMap[`isedit${T}`] = !dataMap[`isedit${T}`]
      }
    })
    const isDistributor = computed(() => {
      /* 1: 分成 2：分销 */
      return store.state.user.userinfo.agent_type === AgentType.DISTRIBUTOR
    })
    const enterprise = computed(() => {
      return store.state.user.userinfo.is_enterprise === AGENTTYPE.COMPANY ? '企业' : '个人'
    })
    onBeforeMount(() => {
      dataMap.getBaseMessage()
    })
    return { ...toRefs(dataMap), isDistributor, enterprise }
  }
})
