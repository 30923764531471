
import Copyboard from './components/Copyboard.vue'
import { SpreadLink } from '@/model/spreadOrder'
import { requestLinkList } from '@/apis/spread'
import requestMiddle, { RequestInter } from '@/utils/request'
import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
import { AgentType } from '@/model/orderModel'
import DistributorTable from '../spread/components/DistributorTable.vue'
import DivideTable from '../spread/components/Table.vue'
interface LinkData{
  requestLink: () => void
  share_link: String
  isdivider: boolean
  linkInfo: Array<SpreadLink<SpreadLink<string | number>>>
}
export default defineComponent({
  components: {
    DistributorTable,
    DivideTable,
    Copyboard
  },
  setup() {
    const currentType = ref('distributor-table')
    const data: LinkData = reactive({
      linkInfo: [],
      share_link: '',
      isdivider: false,
      requestLink: async() => {
        const requestData = await requestMiddle({
          requestMethodName: requestLinkList
        } as RequestInter)
        if (requestData[0].agent_type === AgentType.DIVIDER) { // 分成代理
          currentType.value = 'divide-table'
          data.isdivider = true
        }
        data.linkInfo = requestData.map((item: SpreadLink<string | number>, index: number) => {
          item.serial = index + 1
          return item
        })
        data.share_link = (requestData.length && requestData[0].share_link) || ''
      }
    })
    onMounted(() => {
      data.requestLink()
    })
    return { ...toRefs(data), currentType }
  }
})
